import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"

import { rhythm } from "../utils/typography"

const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
        childImageSharp {
          fixed(width: 100, height: 100, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      site {
        siteMetadata {
          author
          description
        }
      }
      resume: assetsJson {
        basics {
          summary
          email
          location {
            city
          }
          profiles {
            network
            url
          }
        }
      }
    }
  `)

  const {
    resume: { basics },
    site: {
      siteMetadata: { author, description },
    },
  } = data

  const links = [
    { network: "Email", url: `mailto:${basics.email}` },
    ...basics.profiles,
  ]

  return (
    <div
      style={{
        display: `flex`,
        alignItems: "center",
        marginBottom: rhythm(2.5),
      }}
    >
      <Image
        fixed={data.avatar.childImageSharp.fixed}
        alt={author}
        style={{
          marginRight: rhythm(1 / 2),
          marginBottom: 0,
          minWidth: 100,
          borderRadius: `100%`,
        }}
        imgStyle={{
          borderRadius: `50%`,
        }}
      />
      <div>
        {description}
        <br />
        {links.map((profile, index) => {
          const link = (
            <a
              href={profile.url}
              key={profile.url}
              rel="noopener noreferrer"
              title={profile.network}
              target="_blank"
            >
              {profile.network}
            </a>
          )

          if (index > 0) {
            return <React.Fragment key={profile.url}> • {link}</React.Fragment>
          } else {
            return link
          }
        })}
      </div>
    </div>
  )
}

export default Bio
